header { width: 100%; }

.header-wrapper {
    height: 8rem;
}

.header-wrapper h1 {
    margin: 0;
    padding: 1.1rem 0 1.3rem 2rem;
    height: 50%;
    color: #ffffff;
}

.header-nav {
    height: 50%;
    background-color: #d9d9d9;
}

.header-nav ul {
    margin: 0;
    list-style: none;
}

.nav-links {
    display: flex;
    padding: 0;
    height: 100%;
}

.menu-nav-links {
    display: flex;
    padding: 0;
}

.header-nav a {
    display: block;
    height: 100%;
    padding: 1.3rem 2rem;
}


.nav-links button {
    padding: 1.3rem 2rem;
    height: 100%;
    background-color: transparent;
}

.header-nav a:hover,
.nav-links .share-btn:hover {
    background-color: #ffffff !important;
}

.bg-dark {
    background: linear-gradient(90deg, rgba(0, 0, 0, 1.0), rgba(120, 120, 120, 1.0));
    background: -webkit-linear-gradient(90deg, rgba(0, 0, 0, 1.0), rgba(120, 120, 120, 1.0));
    background: -moz-linear-gradient(90deg, rgba(0, 0, 0, 1.0), rgba(120, 120, 120, 1.0));
    background: -ms-linear-gradient(90deg, rgba(0, 0, 0, 1.0), rgba(120, 120, 120, 1.0));
    background: -o-linear-gradient(90deg, rgba(0, 0, 0, 1.0), rgba(120, 120, 120, 1.0));
}

.selected {
    background: #ffffff;
}

/* mobile nav */
.menu button {
    position: relative;
    display: none;
}

@media screen and (max-width: 1139px) {
    .header-wrapper { height: 6.8rem; }

    .header-wrapper h1 {
        padding: 1.1rem 0 1.3rem 1.4rem;
    }

    .header-nav a,
    .header-nav button {
        padding: 1rem 1.4rem;
    }

    .menu button {
        display: inline-block;
    }

    .menu-nav-links {
        display: none;
        position: absolute;
        flex-direction: column;
        padding: 0.9rem 0.2rem 0.9rem 0;
        /* width: calc(100% - var(--panel-padding)*2); */
        width : 100%;
        z-index: 99;

        background-color: #ffffff;
        /* border-bottom: 0.2rem solid silver; */
        /* box-shadow: 0 0.2rem 0 0 grey; */
        box-shadow: inset -0.1rem -0.1rem #0a0a0a,
            inset 0 0,
            inset -0.2rem -0.2rem grey,
            inset 0.2rem 0.2rem #fff;
    }

    .open button { background-color: #fff; }
    .open .menu-nav-links {
        display: flex;
    }

    a.selected {
        position: relative;
        padding-left: 3.2rem;
    }

    a.selected::before {
        position: absolute;
        content: ">";
        left: 1.4rem;
    }
}
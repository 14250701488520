@font-face {
    font-family: TransylvanianBold;
    src: url(assets/fonts/TransylvanianBold/font.woff) format('woff'), url(assets/fonts/TransylvanianBold/font.woff2) format('woff2');
}

:root {
    --vh: 100%;
    --panel-padding: 0.4rem;
}

html, body {
    padding: 0;
    margin: 0;
    font-size: 10px;
    line-height: 1;
    font-family: 'Courier New', Courier, monospace;
    font-weight: bold;
}

body {
    height: 100%;
    touch-action: none;
}

#root {
    height: 100%;
}

img, iframe {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
}

a {
    color: #000;
}

a:hover {
    color: #000;
}

button {
    padding: 0;
    border: 0 solid #ffffff;
    outline: none;
    cursor: pointer;
}

h1, a, button {
    font-size: 1.4rem;
    font-weight: bold;
    line-height: 1;
}

.line98 {
    border: 0.2rem solid #cccacc;
}

.line98-inset {
    border: 0.2rem inset #dcdbdc;
}

.panel98 {
    background: silver;
    box-shadow: inset -0.1rem -0.1rem #0a0a0a,
        inset 0.1rem 0.1rem #dfdfdf,
        inset -0.2rem -0.2rem grey,
        inset 0.2rem 0.2rem #fff;
    padding: var(--panel-padding);
}

.btn98 {
    background: silver;
    box-shadow: inset -1px -1px #0a0a0a,inset 1px 1px #fff,inset -2px -2px grey,inset 2px 2px #dfdfdf;
    border: none;
    border-radius: 0;
    box-sizing: border-box;
}

.btn98:active:enabled {
    box-shadow: inset -1px -1px #fff,inset 1px 1px #0a0a0a,inset -2px -2px #dfdfdf,inset 2px 2px grey;
}

button.btn98:disabled {
    text-shadow: 0.1rem 0.1rem 0 #ffffff;
    color: grey;
}

.app {
    height: 100%;
}

.wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #fff;
}

.grabbable {
    cursor: move;
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
}

.grabbable:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
}

.hide { display: none; }

/* Exhibition page */
.expo {
    height: 100%;
    overflow-y: scroll;
}

.expo-wrapper {
    padding: 8rem 0;
    text-align: center;
}

.expo-wrapper .content {
    margin: 0 auto;
    width: 100%;
    max-width: 140rem;
}

.expo-wrapper h2 {
    font-size: 2.8rem;
    font-weight: bold;
}

.expo-wrapper h2,
.expo-wrapper .calendar {
    margin-bottom: 4rem;
}

.expo-wrapper .description {
    margin: 0 auto 4rem;
    padding: 0 1.4rem;
    font-size: 1.4rem;
}

.expo-wrapper .description p {
    margin-bottom: 0;
    line-height: 1.6;
    word-break: keep-all;
}

.expo-wrapper .calendar {
    display: inline-block;
    padding: 1.5rem 1.6rem;
}

.expo-wrapper img {
    width: 100%;
}

.expo-wrapper img.offline {
    max-width: 69.5rem;
    margin-top: 10rem;
    padding: 0 4rem;
}

.expo-wrapper .year {
    display: block;
    margin-top: 10rem;
    font-size: 2.8rem;
}

@media screen and (max-width: 1139px) {
    .expo-wrapper { padding: 4rem 0; }
    .expo-wrapper h2 { margin-bottom: 3.2rem; }
    .expo-wrapper .calendar { margin-bottom: 2rem; }

    .expo-wrapper img.offline { margin-top: 4rem; }

    .expo-wrapper .description { margin: 0 auto 2rem; }

    .expo-wrapper h2 { font-size: 2rem; }
    .expo-wrapper .year {
        margin-top: 4rem;
        font-size: 2rem;
    }
}
.canvas-wrapper {
    position: relative;
    height: 100%;
    overflow: hidden;
}

pre, canvas {
    display: block;
    margin: 0 !important;
    width: 102%;
    height: 102%;
    font-family: inherit;
    overflow: hidden !important;
}

pre span {
    display: inline-block;
}

.canvas-tools {
    position: fixed;
    bottom: 1.6rem;
    right: 1.6rem;
    display: flex;
    flex-direction: column;
}

.canvas-tools button {
    width: 5rem;
    height: 5rem;
    font-size: 2.8rem;
    cursor: pointer;
}

.capture {
    position: fixed;
    bottom: 2rem;
    left: 50%;
    transform: translateX(-50%);
    padding: 1.6rem 5rem 1.8rem;
}

@media screen and (max-width: 1139px) {
    .canvas-tools {
        bottom: 1rem;
        right: 1.2rem;
    }

    .capture { bottom: 1rem; }
}
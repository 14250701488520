:root {
    --slides-gap: 0;
    --ctrl-img-width: 4rem;
    --ctrl-height: 5rem;
}

.slide-show__wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.slide-show__wrapper:after {
    position: absolute;
    content: "";
    left: 50%;bottom: 0;
    transform: translateX(-50%);
    width: var(--ctrl-img-width);
    width: 4rem;
    height: calc(var(--ctrl-height) - 0.2rem);
    height: 4.8rem;
    border: 0.2rem solid #ff6500;
    pointer-events: none;

    z-index: 99;
}

.main-image {
    padding: 1.5rem 0;
    height: 100%;
    flex: 1;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-origin: content-box;
    transition: background-image .1s ease-in-out;
}


.iframe-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    visibility: hidden;
}

.iframe-wrapper.show {
    visibility: visible;
}

.tnt-nb-video {
    margin: auto;
    width: 100%;
    height: 100%;
    max-width: 113.2rem;
    max-height: 63.675rem;
}

.slide-show__wrapper .controller {
    height: var(--ctrl-height);
    height: 5rem;
    background-color: #dfdfdf;
    overflow: hidden;
    cursor: grab;
}
.slide-show__wrapper .controller:active {
    cursor: grabbing;
}

.slide-show__wrapper .controller ul {
    display: flex;
    width: fit-content;
    height: 100%;
    padding: 0;
    padding-top: 0.2rem;
    margin-bottom: 0;
    list-style: none;
}

.slide-show__wrapper .controller li {
    width: 4rem;
    padding: 0.1rem;
    box-shadow: inset -0.1rem -0.1rem #dfdfdf, inset 0.1rem 0.1rem grey;
}
.slide-show__wrapper .controller li img {
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 1139px) {
    .tnt-nb-video {
        max-width: calc(100vw - 0.8rem);
        max-height: calc((100vw - 0.8rem)*0.5625);
    }
    .main-image {
        padding: 0;
    }
    .main-image.mobile {
        background-size: cover;
    }
}
.loading-wrapper {
    position: absolute;
    top: 0;
    display: flex;
    width: 100%;
    height: 100%;
    background-color: #fff;
}
.loading-wrapper ::before{
    content: '';
    width:100%;
    height: 100%;
    background-color: #fff;
}

.loading-wrapper video {
    width: 100%;
    clip-path: inset(1px 1px);
}

.mobile .loading-wrapper video {
    object-fit: cover;
}
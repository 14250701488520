:root {
    --grid-width: 118rem;
    --col-gap: 1rem;
    --row-gap: 1rem;
    --mb-col-gap: 0.5rem;
    --mb-row-gap: 0.5rem;
    --img-width: calc((100vw - 0.8rem)*0.1);
    --mb-img-width: calc((100vw - 0.8rem)*0.2);
}

.catalog-grid {
    height: 100%;
    overflow-y: scroll;
}

.catalog-grid-wrapper {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
    overflow: hidden;
}

.catalog-grid-wrapper ul {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    padding: 2rem 0;
    width: 100%;
}

.catalog-grid-wrapper ul.zoomed {
    grid-template-columns: repeat(1, 1fr);
    width: 60%;
}

.catalog-grid-wrapper li {
    display: table;
    list-style: none;
}

.catalog-grid-wrapper ul.zoomed .pixelator {
    height: calc((100vw - 0.8rem)*0.6);
}

.pixelator {
    display: table-cell;
    vertical-align: middle;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: var(--img-width);

    transition: background-color 0.2s linear;
}

.pixelator img {
    display: block;
    width: 100%;

    transition: opacity 0.2s linear;
    cursor: pointer;
}

.tools {
    position: fixed;
    right: 1.6rem;
    bottom: 1.6rem;
    display: flex;
    flex-direction: column;
}

.tools button {
    width: 5rem;
    height: 5rem;
    font-size: 2.8rem;
    cursor: pointer;
}

@media screen and (max-width: 1139px) {
    .catalog-grid-wrapper ul {
        grid-template-columns: repeat(5, 1fr);
        padding: 1rem 0;
    }
    .catalog-grid-wrapper ul.zoomed {
        width: 100%;
    }
    .catalog-grid-wrapper ul.zoomed .pixelator {
        height: calc((100vw - 0.8rem));
    }
    .pixelator { height: var(--mb-img-width); }
    .tools {
        bottom: 1rem;
        right: 1.2rem;
    }
}
.face-btn {
    position: fixed;
    left: 1rem;bottom: 1rem;
    width: 5rem;
    height: 5rem;
    font-size: 1rem;
    line-height: 1.2rem;
}

@media screen and (min-width: 1140px) {
    .face-btn {
        bottom: 1.6rem;
        left: 1.6rem;
    }
}